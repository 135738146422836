import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

type FeatureFlagSwitchProps = {
  /**
   * The key of the flag from Launch Darkly to be used. This is constrained to keys that are
   * auto generated using `npm run flaggen` and they pull directly from the relevant environment
   */
  flagKey: keyof FeatureFlags; // The prop for flag key, constrained to valid feature flag keys
  flagEnabled: React.ReactNode; // Component to display when the feature is enabled
  flagDisabled: React.ReactNode; //Component to display when the feature is disabled
};

export const FeatureFlagSwitch: React.FC<FeatureFlagSwitchProps> = ({ flagKey, flagDisabled, flagEnabled }) => {
  const flags = useFlags<FeatureFlags>();
  const isFlagEnabled = flags[flagKey];
  return isFlagEnabled ? flagEnabled : flagDisabled;
};
